import WaveSurfer from "wavesurfer.js"

mm.BasePlayer = function ($el) {
  "use strict"

  if (typeof $el === "undefined" || typeof Audio === "undefined") return

  var self = mm.EventEmitter(),
    $spinTarg = $(".mm-player-controls", $el),
    _deepest = 0,
    _replay = false,
    _segmentStart,
    _spinner = new mm.Spinner(),
    _station = null,
    _songVersion = null,
    playlistType = "",
    selectedStation = null

  /**
   * Initializer, should be overwritten/super in each instance of BasePlayer
   * @public
   */
  self.init = function () {
    self.createAudio(function () {
      self.bindEvents()
      self.setState("idle")
      if (mm.user.isLoggedIn()) {
        permitActions("show")
      } else {
        permitActions("hide")
      }
    })

    self.$playerActionsMenu.off("click")
    self.$playerActionsMenu.on("click", function () {
      $(this).hasClass("expanded") ? hideActionsMenu() : showActionsMenu()
    })

    self.$downloadOptionsButton.off("click")
    self.$downloadOptionsButton.on("click", function () {
      $(this).hasClass("expanded") ? hideDownloadOptions() : showDownloadOptions()
    })
  }

  /**
   * searching for song version ID in users favorites
   *
   */
  // TODO refactor this so we're not using an AJAX
  // We can probably stick this in the serializer
  // to check if the user has favorited this song version
  function searchFavorite(songVersionID, callback) {
    if (_.isUndefined(songVersionID)) return false

    $.get("/favorites/search/song_version/" + songVersionID).then(function (data) {
      var result = data.success

      if (typeof callback === "function") {
        callback(result, songVersionID)
      }
    })
  }

  /**
   * Callback for searchFavorite function:
   * checking song version for favorite status
   * then styling the favorite icon accordingly
   */
  function styleFavorite(result, songVersionID) {
    var $favoriteIcon = $(".add-favorite .favorite-icon", $el)
    var $favoriteText = $(
      '.add-favorite .label, .connect-label:contains("Favorites")',
      "[data-song-version-id=" + songVersionID + "]"
    )
    var $favoriteConnectText = $(
      ".mm-connect-right .connected",
      "[data-song-version-id=" + songVersionID + "]"
    )

    if (!result) {
      $favoriteIcon.removeClass("selected")
      $favoriteText.text("Add To Favorites")
      $favoriteConnectText.text("Add To Favorites")
    } else {
      $favoriteIcon.addClass("selected")
      $favoriteText.text("Remove Favorite")
      $favoriteConnectText.text("Remove Favorite")
    }
  }

  /**
   * Toggle permissable actions based on user status (mixtape, download, favorite)
   * @private
   * @param  {String} visibility JQuery function, 'show' or 'hide'
   */
  function permitActions(visibility) {
    visibility = visibility || "hide"

    var mixtape = $(".action.mixtape:not(.disabled)", $el),
      download = $(".action.download:not(.disabled)", $el),
      favorite = $(".action.favorite:not(.disabled)", $el),
      projectSearch = $(".action.project-search", $el)

    var disabled_mixtape = $(".action.mixtape.disabled", $el),
      disabled_download = $(".action.download.disabled", $el),
      disabled_favorite = $(".action.favorite.disabled", $el)

    mixtape.add(download).removeClass("show hide").addClass(visibility)

    disabled_mixtape.add(disabled_download).addClass("show hide").removeClass(visibility)

    favorite.add(download).removeClass("show hide").addClass(visibility)

    disabled_favorite.add(disabled_download).addClass("show hide").removeClass(visibility)

    if (visibility === "hide" || mm.user.attributes.internal_user) {
      projectSearch.add(projectSearch).removeClass("show hide").addClass(visibility)
    }

    if ("resize" in self) self.resize()
  }

  /**
   * Shifts a song version from the queue and initiates GET request for playback
   * @private
   */
  function playSongVersion() {
    var songVersion = self.queue[self.pointer]
    var type = "mp3"
    var src

    if (songVersion) {
      if (!audioEl.canPlayType("audio/mpeg")) {
        console.error("Your browser does not accept our audio formats.")
      }
      $spinTarg.addClass("spinning")
      if (mm.isMobile) {
        mm.spin(true)
      } else {
        _spinner.spin($spinTarg[0])
      }

      src = "/song_versions/play/" + songVersion.id + "?type=" + type
      if (_replay) {
        src += "&replay=true"
      }
      if (mm.isMobile) {
        // This uses the basic HTML5 audio element instead of mediaElement.js
        audioEl.pause()
        audioEl.src = src
        audioEl.play()
      } else {
        // Using mediaElement.js on desktop so that the waveform and other fun stuff works
        self.audio.pause()
        self.audio.setSrc(src)
        self.audio.load()
      }

      self.injectPlaying(songVersion)
      addHighlight(songVersion.id)
      searchFavorite(songVersion.id, styleFavorite)

      self.buildWaveform(songVersion)
    }
  }

  function addHighlight(id) {
    var $songVersionItem = $("li.song-version-item[data-song-version-id=" + id + "]")
    removeHighlight()
    if ($songVersionItem.length > 0) $songVersionItem.addClass("playing")
  }

  function removeHighlight() {
    $("li.song-version-item.playing").removeClass("playing")
  }

  function hideActionsMenu() {
    $(window).off("click")
    self.$playerExpandActionsMenu.removeClass("expanded")
    self.$playerActionsMenu.removeClass("expanded")
  }

  function showActionsMenu() {
    //Clean up other menu and click events
    $(window).off("click")
    self.$downloadOptionsMenu.removeClass("expanded")
    self.$downloadOptionsButton.removeClass("expanded")
    // Set UI and behavior
    self.$playerExpandActionsMenu.addClass("expanded")
    self.$playerActionsMenu.addClass("expanded")
    $(window).on("click", function (e) {
      if (
        !e.target.closest([
          "div.player-actions-menu",
          ".expand-player-actions-menu .horizontal-ellipsis",
        ])
      ) {
        hideActionsMenu()
      }
    })
  }

  function showDownloadOptions() {
    //Clean up other menu and click events
    $(window).off("click")
    self.$playerExpandActionsMenu.removeClass("expanded")
    self.$playerActionsMenu.removeClass("expanded")
    // Set UI and behavior
    self.$downloadOptionsButton.addClass("expanded")
    self.$downloadOptionsMenu.addClass("expanded")
    $(window).on("click", function (e) {
      if (!e.target.closest(["div.download-options-menu", ".download"])) {
        hideDownloadOptions()
      }
    })
  }

  function hideDownloadOptions() {
    $(window).off("click")
    self.$downloadOptionsButton.removeClass("expanded")
    self.$downloadOptionsMenu.removeClass("expanded")
  }

  var audioEl = new Audio()
  $("body").append(audioEl)

  self.$el = $el

  self.$playerExpandActionsMenu = $("div.player-actions-menu", $el)
  self.$playerActionsMenu = $(".expand-player-actions-menu .horizontal-ellipsis", $el)
  self.$downloadOptionsMenu = $("div.download-options-menu", $el)
  self.$songVersionDetails = $(".song-version-details", $el)
  self.$songVersionTime = $(".song-version-time", $el)
  self.$songVersionStream = $(".song-version-stream", $el)
  self.$songVersionWaveform = $(".waveform", $el)

  self.$close = $(".close-history", self.$el)
  self.$historyButton = $(".mm-history", self.$el)

  self.$deck = $(".player-deck", $el)
  self.$scrubber = $(".mm-scrubber", $el)
  self.$photo = $(".artist-image img", $el)
  self.$photoLink = $(".song-version-link", $el)
  self.$name = $(".artist-name", $el)
  self.$title = $(".song-version-title", $el)
  self.$actionWrap = $(".mm-player-actions", $el)
  self.$downloadOptionsButton = $(".download", self.$actionWrap)
  self.$actionLyrics = $(".lyrics", self.$actionWrap)
  self.$actionCustomize = $(".customize", self.$actionWrap)
  self.$favoriteIcon = $(".icon.favorite-icon", self.$actionWrap)
  self.$favoriteText = $('.add-favorite .label, .connect-label:contains("Favorite")', $el)
  self.$elapsed = $(".elapsed", $el)
  self.$total = $(".total", $el)
  self.$nextButton = $(".action.next", $el)
  self.$playButton = $(".action.play", $el)
  self.$prevButton = $(".action.prev", $el)
  self.$volume = $(".volume", $el)
  self.$volumeSlider = $(".volume-slider", $el)
  self.$songVersionActions = $(".song-version-actions", $el)

  self.bufferEnd = 0
  //self.isRadio = false
  self.pointer = 0
  self.queue = []
  self.streamWidth = 0
  self.radioQueue = []
  self.favorited = undefined

  /**
   * Native canplay event handler, bind click event to allow seeking
   * @public
   * @param  {Object} e canplay event object
   */
  self._onCanplay = function (e) {
    self.$songVersionWaveform.off("click").on("click", function (evt) {
      evt.preventDefault()
      if (evt.target.tagName === "A") {
        return false
      }
      var offX = evt.offsetX || evt.clientX - $(evt.target).offset().left,
        seekTime = ((offX / self.streamWidth) * self.audio.duration).toFixed(14)
      self.audio.setCurrentTime(seekTime)
    })
    if (mm.isMobile) {
      mm.spin(false)
    } else {
      _spinner.stop()
    }
    $spinTarg.removeClass("spinning")
    if (self.audio.paused) self.audio.play()
    self.$volume.show()
  }

  /**
   * Native ended event handler, resets player states and attempts to play the
   * next song version in the queue
   * @public
   * @param  {Object} e ended event object
   */
  self._onEnded = function (e) {
    self.$playButton.attr("data-action", "play")
    $el.removeClass("playing")
    removeHighlight()
    self.next()
  }

  /**
   * Native loadedmetadata event handler, pretty prints audio duration when
   * audio meta data has been recieved from request
   * @public
   * @param  {Object} e loadedmetadata event object
   */
  self._onLoadedmetadata = function (e) {
    var pretty = new Date(null)
    pretty.setSeconds(self.audio.duration)
    pretty = pretty.toTimeString().substr(3, 5).replace(/^0+/, "")
    self.$elapsed.html("0:00")
    self.$total.html(pretty)
    pretty = null
  }

  /**
   * Native pause event handler, sets the state of mm.player to 'paused'
   * @public
   * @param  {Object} e pause event object
   */
  self._onPause = function (e) {
    self.setState("paused")
  }

  /**
   * Native play event handler, sets the state of mm.player to 'playing'
   * @public
   * @param  {Object} e play event object
   */
  self._onPlay = function (e) {
    self.setState("playing")
  }

  /**
   * Native progress event handler, checks the buffered length
   * and resizes it
   * @public
   * @param  {Object} e progress event object
   */
  self._onProgress = function (e) {
    if (self.audio.buffered.length) {
      self.bufferEnd = self.audio.buffered.end(0)
    }
  }

  /**
   * Native timeupdate event handler, pretty prints current audio position and
   * calls to resize the elapsed progress bars
   * @private
   * @param  {Object} e timeupdate event object
   */
  self._onTimeupdate = function (e) {
    if (mm.isMobile) {
      mm.spin(false)
    }
    var pretty = new Date(null)
    pretty.setSeconds(self.audio.currentTime)
    // TODO some deprecated substr code here
    pretty = pretty.toTimeString().substr(3, 5).replace(/^0/, "")
    self.$elapsed.html(pretty)
    pretty = null
    self.sizeElapsed()
  }

  self.createAudio = function (callback) {
    self.audio = new MediaElement(audioEl, {
      pluginPath: "/assets/",
      enablePluginDebug: false,
      type: "audio/mp3",
      mode: "auto",
      success: function (audio) {
        self.audio = audio
        if (callback) callback()
      },
      error: function (e) {
        console.log("Error creating audio player.", e)
      },
    })
  }

  /**
   * Bind audio and user generated event listeners
   * @public
   */
  self.bindEvents = function () {
    self.audio.preload = "auto" // required

    self.audio.addEventListener("canplay", self._onCanplay, false)
    self.audio.addEventListener("ended", self._onEnded, false)
    self.audio.addEventListener("loadedmetadata", self._onLoadedmetadata, false)
    self.audio.addEventListener("pause", self._onPause, false)
    self.audio.addEventListener("play", self._onPlay, false)
    self.audio.addEventListener("progress", self._onProgress, false)
    self.audio.addEventListener("timeupdate", self._onTimeupdate, false)

    self.$nextButton.on("click", function (e) {
      e.preventDefault()
      self.next()
    })
    self.$prevButton.on("click", function (e) {
      e.preventDefault()
      self.prev()
    })
    self.$volumeSlider.on("input change", function (e) {
      e.preventDefault()
      var amount = this.value / 100
      self.volume(amount)
    })
  }

  /**
   * Injects song version information into the player. Song Version name, Artist name, etc
   * Creates a mm.SongVersion instance from this data
   * @public
   * @param  {Object} song version Song Version object attributes
   */
  self.injectPlaying = function (songVersion) {
    if (typeof songVersion === "undefined") return false
    if (_songVersion !== null) {
      _songVersion.destroy()
      _songVersion = null
    }
    var displayName = songVersion.display_name || songVersion.displayName
    var hasClickLicenses = songVersion.has_click_licenses || songVersion.hasClickLicenses
    var playerArt = songVersion.player_art || songVersion.playerArt
    var isExclusive = songVersion.exclusive
    var isVintage = songVersion.vintage

    self.$photo.attr("src", playerArt)
    self.$photo.attr("width", 46)
    self.$photoLink.attr("data-bypass", true).attr("href", "/browse/" + songVersion.id)
    self.$name
      .html(songVersion.artist.name)
      .attr("data-bypass", true)
      .attr("href", "/artists/" + songVersion.artist.slug)
    self.$title
      .html(displayName)
      .attr("data-bypass", true)
      .attr("href", "/browse/" + songVersion.id)
    self.$deck
      .attr("data-song-version-id", songVersion.id)
      .attr("data-song-version", JSON.stringify(songVersion))
    $el
      .attr("data-song-version-id", songVersion.id)
      .attr("data-song-version", JSON.stringify(songVersion))
    mm.facade.trigger("bypass")

    var action = !songVersion.lyrics ? "addClass" : "removeClass"
    $(".action.lyrics", $el)[action]("hide")

    action = !songVersion.customizable ? "addClass" : "removeClass"
    $(".action.customize", $el)[action]("hide")

    action = !hasClickLicenses ? "addClass" : "removeClass"
    $(".action.license", $el)[action]("custom-only")

    if (hasClickLicenses) {
      $(".action.license", $el).off("mouseover.tooltip")
      $(".action.license", $el).find("span.button-text").html("Buy License")
    } else {
      $(".action.license", $el).find("span.button-text").html("Custom License")
    }
    if (isExclusive && isVintage) {
      $(".tooltip.partial-exclusivity-tooltip.desktop", $el).addClass("double-tip")
      $(".tooltip.partial-exclusivity-tooltip.desktop p", $el).off("mouseover.tooltip")
      $(".tooltip.partial-exclusivity-tooltip p", $el).html(
        "This song has some restrictions and vintage songs are priced at a higher rate, contact us for a custom license."
      )
    } else if (isExclusive) {
      $(".tooltip.partial-exclusivity-tooltip.desktop p", $el).html(
        "This song has some restrictions, contact us for conditions and terms."
      )
    } else if (isVintage) {
      $(".tooltip.vintage-tooltip.desktop p", $el).html(
        "Vintage songs are priced at a higher rate, contact us for a custom license."
      )
    }
    action = !songVersion.exclusive && !songVersion.vintage ? "addClass" : "removeClass"
    $(".tooltip.partial-exclusivity-tooltip", $el)[action]("hide")

    var lowRes = $(".mm-low-res a", self.$downloadOptionsMenu)
    lowRes.attr("href", "/song_versions/" + songVersion.id + "/download/low")

    var highRes = $(".mm-high-res a", self.$downloadOptionsMenu)
    highRes.attr("href", "/song_versions/" + songVersion.id + "/download/high")

    if (songVersion.has_stems || songVersion.hasStems) {
      var stemsDownload = $(".mm-download-stems a", self.$downloadOptionsMenu)
      var stemsOption = $(".mm-download-stems", self.$downloadOptionsMenu)
      stemsOption.removeClass("disabled")
      stemsDownload.attr("href", "/song_versions/" + songVersion.id + "/download_stems")
    } else {
      var stemsDownload = $(".mm-download-stems a", self.$downloadOptionsMenu)
      var stemsOption = $(".mm-download-stems", self.$downloadOptionsMenu)
      stemsOption.addClass("disabled")
      stemsDownload.attr("href", "/#")
    }

    var downloadLink = $(".action.download a.mm-hover-connect", $el)
    if (isExclusive) {
      if (!(mm.user.attributes.role == "admin" || mm.user.attributes.role == "staff")) {
        $(".action.download", $el).addClass("disabled")
        downloadLink.removeAttr("href")
      }
    }

    if (mm.isMobile) {
      _songVersion = mm.SongVersion($el)
    } else {
      _songVersion = mm.SongVersion(self.$deck)
    }

    self.$close.on("click", function (e) {
      e.preventDefault()
      self.$el.removeClass("expanded")
    })

    self.$historyButton.on("click", function (e) {
      e.preventDefault()

      if (self.$el.hasClass("expanded")) {
        self.$el.removeClass("expanded")
      } else {
        self.$el.addClass("expanded")
        mm.desktopPlayer.fetchHistory()
      }
    })

    mm.HoverConnect($("a", self.$historyButton), self.$deck)
  }

  self.buildWaveform = function (songVersion) {
    var fileUrl = songVersion.songVersionFile
    var $waveEl = $("wave", ".waveform")
    var container, progressColor

    if (mm.isMobile || mm.isMobile) {
      container = ".waveform-mobile"
      progressColor = "#fff"
    } else {
      container = ".waveform-desktop"
      progressColor = "#21928e"
    }

    if ($waveEl.length === 0) {
      var wavesurfer = WaveSurfer.create({
        backend: "WebAudio",
        barGap: 1.5,
        barWidth: 1.5,
        container: container,
        cursorColor: "transparent",
        cursorWidth: 0,
        height: 50,
        pixelRatio: 2,
        progressColor: progressColor,
        waveColor: "#ccc",
      })

      // Get signed URL for Algolia-based playSerializer objects without a song_version_file
      if (!fileUrl) {
        $.get("/api/v1/song_versions/" + songVersion.id + "/get_signed_url").then(function (data) {
          songVersion.songVersionFile = data.url
          $waveEl.remove()
          if (data.url) {
            self.buildWaveform(songVersion)
          } else {
            console.log("No audio file found")
            _spinner.stop()
            $spinTarg.removeClass("spinning")
            return
          }
        })
      } else {
        wavesurfer.load(fileUrl)
      }
    } else {
      $waveEl.remove()
      self.buildWaveform(songVersion)
    }
    self.$waveElapsed = $("wave>wave", $el)
    self.$waveCanvases = $("canvas", $el)
  }

  /**
   * Attempt to advance the player pointer further into the queue
   * @public
   */
  self.next = function () {
    var newPosition = self.pointer + 1,
      queueLength = self.queue.length

    // if (playlistType !== "mixtape") {
    //   // if next button is hit and postion is 2 from end of queue and queue is divisible by 11, load in more song versions
    //   if (self.pointer == queueLength - 3 && queueLength % 10 !== 0)
    //     feedSelectedStation(selectedStation)
    //   // if next button is hit and postion is 2 from end of queue, load more song versions into default station
    //   if (self.pointer == queueLength - 2 && queueLength % 10 == 0) feedDefaultStation()
    // }

    // we're already at the end
    if (self.pointer >= queueLength - 1) {
      // check if we are in a playlist
      if (_segmentStart > 0 || playlistType === "mixtape") {
        self.pointer = _segmentStart
        _replay = false
        _deepest = self.pointer
        mm.playerProxy.trigger("next")
        return playSongVersion()
      }

      // otherwise do nothing
      return false

      // we reached the end
    } else if (newPosition >= queueLength - 1) {
      // if we aren't in a playlist, disable the next button
      if (_segmentStart < 0) {
        self.$nextButton.addClass("disabled")
      }

      // advance to end of queue
      self.pointer = queueLength - 1

      // new listen event for user
      if (self.pointer > _deepest) {
        _replay = false
        _deepest = self.pointer

        // we are currently replaying history
      } else {
        _replay = true
      }

      mm.playerProxy.trigger("next")
      return playSongVersion()
    }

    // all good, advance
    self.pointer = newPosition

    // new listen event for user
    if (self.pointer > _deepest) {
      _replay = false
      _deepest = self.pointer

      // we are currently replaying history
    } else {
      _replay = true
    }

    mm.playerProxy.trigger("next")
    return playSongVersion()
  }

  /**
   * Pauses audio playback at currentTime
   * @public
   */
  self.pause = function () {
    removeHighlight()
    self.audio.pause()
  }

  // if another statioin is clicked, update selectedStation var
  $("[data-uuid]").on("click", function () {
    selectedStation = $(this).data("uuid")
  })

  /**
   * Public method to play a song version, only adds the song version(s) to the queue and
   * calls playSongVersion to handle what to play
   * @public
   * @param  {Number|Array} data Song Version ID(s)
   */
  self.play = function (data, radio) {
    if (!data) {
      addHighlight(self.$el[0].dataset.songVersionId)
      return self.audio.play() // resume
    }
    //self.isRadio = typeof radio === "undefined" ? false : radio

    if (playlistType === "mixtape" && data.pointer) {
      // put the position pointer at the song that was clicked within the new array added
      var songVersion = data.songVersions.find(function (el) {
        return el.id === data.pointer.id
      })

      self.queue = data.songVersions
      self.pointer = self.queue.indexOf(songVersion)
      _segmentStart = self.queue.indexOf(data.songVersions[0])

      // we are somewhere within the queue, enable the next button
      self.$nextButton.removeClass("disabled")
    } else if (playlistType === "station" || Array.isArray(data)) {
      // put the position pointer at the start of the new array added
      var songVersion = data[0],
        pointer

      self.queue = self.queue.concat(data)

      pointer = self.queue.indexOf(songVersion)
      self.pointer = _segmentStart = pointer

      // we are somewhere within the queue, enable the next button
      self.$nextButton.removeClass("disabled")
    } else if (playlistType === "search round") {
      self.queue = [data]
      self.pointer = 0
      _segmentStart = -1

      self.$nextButton.addClass("disabled")
      self.$prevButton.addClass("disabled")
    } else {
      self.queue.push(data)
      self.pointer = self.queue.length - 1
      _segmentStart = -1

      // we're at the end of the queue, disable the next button
      self.$nextButton.addClass("disabled")
    }
    _replay = false
    _deepest = self.pointer

    // showing song version actions before calling the playSongVersion function
    // so that the buffer bar size knows where to stop on init.
    if (self.$songVersionActions.not(":visible")) self.$songVersionActions.show()
    playSongVersion()
  }

  /**
   * Attempt to move the player pointer back in the history, unless the pointer
   * is already at the beginning of the queue, or the current song version has more
   * than 5 seconds elapsed
   * @public
   */
  self.prev = function () {
    if (self.audio.currentTime > 5 || (self.pointer === 0 && playlistType !== "mixtape")) {
      // return to the beginning of the song version
      return self.audio.setCurrentTime(0)
    }
    self.$nextButton.removeClass("disabled")
    _replay = true
    // if the player is at the beginning of a mixtape, loop it back to the last song
    self.pointer =
      playlistType === "mixtape" && self.pointer === 0 ? self.queue.length - 1 : self.pointer - 1
    mm.playerProxy.trigger("back")
    return playSongVersion()
  }

  self.volume = function (amount) {
    self.audio.setVolume(amount)
  }

  /**
   * Sizes the elapsed progress bar
   * @public
   */
  self.sizeElapsed = function () {
    var w = (self.audio.currentTime / self.audio.duration) * self.streamWidth,
      $waveElapsed = self.$waveElapsed || $("wave>wave", $el)

    if (w >= self.streamWidth) {
      w = self.streamWidth
    }
    $waveElapsed.width(w)
  }

  /**
   * Sizes the progress and elapsed waveform canvases
   * @public
   */
  self.sizeWaveform = function () {
    var w = (self.bufferEnd / self.audio.duration) * self.streamWidth
    var $waveCanvases = self.$waveCanvases || $("canvas", $el)

    if (self.$songVersionStream.width() < 427) {
      w = self.$songVersionStream.width() - self.$songVersionTime.outerWidth(true)
    }
    $waveCanvases.width(w)
  }

  self.states = {
    onIdle: function () {
      console.log("base_player.js | onIdle")
    },
    offIdle: function () {
      console.log("base_player.js | offIdle")
    },

    onPlaying: function () {
      $el.addClass("has-song-version playing")
      self.$playButton.attr("data-action", "pause")
    },
    onPaused: function () {
      $el.removeClass("playing")
      self.$playButton.attr("data-action", "play")
    },
  }

  self.on("history", function (payload) {
    var data = payload.data.reverse()
    if (payload.wipe) {
      self.queue = data
      return (self.pointer = 0)
    }
    // grab the current song version
    var songVersion = self.queue[self.pointer]
    self.queue = data.concat(self.queue)

    // find the song versions position in the altered queue
    self.pointer = self.queue.indexOf(songVersion)
  })

  self.on("pause", self.pause)
  self.on("play", function (payload) {
    var data
    playlistType = (payload && payload.type) || ""
    if (_.isObject(payload) && _.has(payload, "songVersions") && !_.has(payload, "pointer")) {
      data = payload.songVersions
    } else if (playlistType === "search round") {
      data = payload.songVersion
    } else {
      data = payload
    }
    self.play(data, false)
  })

  self.on("favorite", (payload) => {
    if (!_songVersion) {
      return
    }

    const { songVersionId, request } = payload
    var $favoriteText = $(
      '.add-favorite .label, .connect-label:contains("Favorites")',
      "[data-song-version-id=" + songVersionId + "]"
    )
    var $favoriteConnectText = $(
      ".mm-connect-right .connected",
      "[data-song-version-id=" + songVersionId + "]"
    )

    if (songVersionId != _songVersion.data.id) {
      return
    }

    if (request == "add") {
      self.$favoriteIcon.addClass("selected")

      $favoriteConnectText.text("Remove Favorite")
      $favoriteText.text("Remove Favorite")
    } else if (request == "remove") {
      self.$favoriteIcon.removeClass("selected")

      $favoriteConnectText.text("Add To Favorites")
      $favoriteText.text("Add To Favorites")
    }
  })

  mm.user.on("logged_in", function () {
    permitActions("show")
  })

  mm.user.on("logged_out", function () {
    permitActions("hide")
  })

  return self
}
